import React from "react";
import { Link } from "react-router-dom";

const GuestSpeakers = () => (
  <div className="guestspeakers">
    <h1>Guest Speakers</h1>
  </div>
);

export default GuestSpeakers;
