import React from "react";
import "../styles/MerchPage.css";
function MerchPage(){

  return (
    <div className="merchPage">
      <div>
        <img src={require("../images/basketball.png")} alt="Basketball" />
      </div>
    </div>
  )
}

export default MerchPage;
